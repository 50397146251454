<template>
  <div>
      <wanalyzer-cell-calculate></wanalyzer-cell-calculate>
  </div>
</template>

<script>
// http://localhost:8094/wanalyzer_cell_calculate_iframe?project_id=d114f02e-428e-4aa0-8c9f-aec19cac2399&client_id=20
import {
  default as WanalyzerCellCalculate
} from '@/components/widgets/WanalyzerCellCalculate';
import { mapGetters } from 'vuex';
export default {
  name: 'wanalyzer_cell_calculate_iframe',
  computed: mapGetters({
    StoreLang: 'StoreLang',
  }),
  components: {
    WanalyzerCellCalculate,
  },
  props: {},
  data () {
    return {};
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {
  },
  watch: {}
}

</script>

